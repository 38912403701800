<template>
  <div class="main-view">
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize" @submit.native.prevent>
        <el-form-item label="轮播图名称" prop="name">
          <el-input
            size="mini"
            v-model.trim="table.params.name"
            placeholder="请输入"
            clearable
            @keydown.enter.native="onSearch"
          ></el-input>
        </el-form-item>
        <el-form-item label="客户名称" prop="merchant_id">
          <el-select v-model="table.params.merchant_id" filterable id="bind_id" clearable placeholder="请输入" @focus="getMerchantSel" @change="onSearch">
            <el-option v-for="item in merchantArr" :label="item.name" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="onSearch">搜索</el-button>
          <el-button icon="el-icon-refresh-right" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="btnAdd">
        <el-button :size="subUnitSize" type="primary" icon="el-icon-plus" @click="showEdit">添加</el-button>
      </div>
    </div>
    
    <VTable
      has-pagionation
      :field="field"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      :showSelect="false"
      :showIndex="true"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:picture="{row}">
        <el-image
          style="width: 60px; height: 60px"
          :src="row.picture"
          fit="cover"
           :preview-src-list="[row.picture]"></el-image>
      </template>
      <template v-slot:target_url="{row}">
        <span v-if="!!row.target_url">{{row.target_url}}</span>
        <span v-if="!!row.goods_name">{{row.goods_name}}</span>
      </template>
      <template v-slot:status="{row}">
        <el-switch
          v-model="row.status"
          :active-value="1"
          :inactive-value="2"
          @change="handleStatus(row)">
        </el-switch>
      </template>
      <template v-slot:action="{row}">
        <el-button type="text" @click="showEdit(row)">编辑</el-button>
        <el-button type="text" @click="remove(row)" v-if="is_sys == 1">删除</el-button>
      </template>
    </VTable>

    <!-- 编辑 -->
    <edit ref="edit"  @refresh="getTable"></edit>

  </div>
</template>

<script>
import Edit from './components/Edit.vue'
import VTable from '@/components/VTable';
import { mixinTable } from '@/mixins/table.js'
export default {
  name: 'BannerIndex',
  components: {
    Edit,
    VTable,
  },
  mixins: [mixinTable],
  data() {
    return {
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      field: [
        { name: "picture", label: "图标", width:'100', hidden: false},
        { name: "name", label: "轮播图名称", showTooltip: true, hidden: false},
        { name: "merchant_str", label: "投放客户", hidden: false},
        { name: "target_url", label: "跳转链接", hidden: false},
        { name: "status", label: "状态", width:'100', hidden: false},
        { name: "create_time", label: "创建时间", width:'160', hidden: false},
        { name: "action", label: "操作",width: "120", fixed:"right", hidden: false }
      ],
      table: {
        loading: false,
        params: {
          name: '',
          merchant_id:'',
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
      merchantArr: [],
    }
  },
  created() {
    this.getTable();
  },
  methods: {
    getTable() {
      this.table.loading = true;
      this.$http.get('/admin/banner/list', {params:this.table.params} ).then(res => {
        if(res.code === 1) {
          this.table.loading = false;
          this.table.data = res.data.list;
          this.table.total = res.data.total;
        }
      })
    },
    // 新增、编辑
    showEdit(row) {
      let dom = this.$refs.edit;
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    // 删除
    remove(row) {
      this.setConfirm(
        `确认删除操作？`,
        {  id: row.id },
        "/admin/banner/delete"
      );
    },
    // 禁用，启用
    handleStatus(row) {
      this.$http.post('/admin/banner/updateStatus', {id: row.id, status: row.status}).then(res => {
        if(res.code == 1) {
          this.$message.success(`${row.status == 1 ? '开启' : '禁用'}成功！`)
        } else {
          this.$message.error(res.msg)
        }
      }).finally(() => {
        this.getTable()
      })
    },
  }
}
</script>
